const getters = {
  sidebar: (state) => state.app.sidebar,
  open_menu: (state) => state.app.opendMenu,
  device: (state) => state.app.device,
  token: (state) => state.user.token,
  user: (state) => state.user.user,
  preferences: (state) => state.user.preferences,
  permission_routes: (state) => state.permission.routes,
  submenu_sidebar: (state) => state.permission.childRoutes,
  roles: (state) => state.user.roles,
};

export default getters;
