import Layout from "@/layout";
const tresoRouter = {
  path: "/rapports",
  component: Layout,
  redirect :"/rapports/index",
  children: [
    {
      path: "index",
      component: () => import("@/views/Rapport/index"),
      name: "rapports",
      hidden: false,
      meta: {
        title: "Rapports",
        icon: "bx bxs-bar-chart-alt-2",
        roles: ["USER-CREATE"], 
      },
    },
    {
      path: "article",
      component: () => import("@/views/Rapport/sale/article/index"),
      name: "rapports",
      hidden: true,
      meta: {
        activeMenu:"/rapports/index",
        title: "Rapport article",
        roles: ["USER-CREATE"], 
      },
    },
    {
      path: "customer",
      component: () => import("@/views/Rapport/sale/customer/index"),
      name: "rapport-customer",
      hidden: true,
      meta: {
        activeMenu:"/rapports/index",
        title: "Rapport client",
        roles: ["USER-CREATE"], 
      },
    },
    {
      path: "revenue",
      component: () => import("@/views/Rapport/sale/revenue/index"),
      name: "rapport-revenue",
      hidden: true,
      meta: {
        activeMenu:"/rapports/index",
        title: "Chiffre d'affaire",
        roles: ["USER-CREATE"],
      },
    },
    {
      path: "purchase/article",
      component: () => import("@/views/Rapport/purchase/article/index.vue"),
      name: "rapport-purchase-article",
      hidden: true,
      meta: {
        activeMenu:"/rapports/index",
        title: "Rapport d'chat par article",
        roles: ["USER-CREATE"],
      },
    },
    {
      path: "purchase/supplier",
      component: () => import("@/views/Rapport/purchase/supplier/index.vue"),
      name: "rapport-purchase-article",
      hidden: true,
      meta: {
        activeMenu:"/rapports/index",
        title: "Rapport d'chat par fournisseur",
        roles: ["USER-CREATE"],
      },
    },

  ],
};

export default tresoRouter;
