<template>
  <div class="acrapid">
    <el-button @click="toggleOpendMenuShortCute">
      <span style="font-weight: bold">CRÉATION RAPIDE</span></el-button
    >
  </div>
</template>

<script>
export default {
  name: "ACCES-RAPID",
  data() {
    return {
      dialogMenu: false,
    };
  },
  methods: {
    toggleOpendMenuShortCute() {
      this.$store.dispatch("app/toggleOpenMenuShortCute");
    },
  },
};
</script>

<style lang="scss" scoped>
.acrapid {
  text-align: center;
  overflow: hidden;
  display: flex;
  font-weight: bold;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 15px;
}
</style>
