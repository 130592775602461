import axios from "axios";
import store from "@/store";
import { getToken } from "@/utils/cookies";

// creation du service axios instance

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 40000,
});

// intercepteur de request

service.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      config.headers["Authorization"] = "Bearer " + getToken();
    }
    return config;
  },
  (error) => {
    return Promise.reject({ ...error });
  }
);

// intercepteur de la reponse

service.interceptors.response.use(
  (response) => {
    const res = response;

    if (res.status !== 200 && res.status !== 201) {
      // complete avec des traitement
      console.log(" status " + res.status);
      if (res.status === 401) {
        window.location.reload();
      } else {
        return Promise.reject("error");
      }
    } else {
      return res.data;
    }
  },
  (error) => {
    if (error.response.status === 401) {
      // window.location.reload();
    }
    return Promise.reject(error.response);
  }
);

export default service;
