import Layout from "@/layout";
const tresoRouter = {
  path: "/tresorie",
  component: Layout,
  children: [
    {
      path: "index",
      component: () => import("@/views/Account/index"),
      name: "tresorie",
      hidden: false,
      meta: {
        title: "Trésorie",
        icon: "bx bxs-wallet-alt",
        bgcolor: "bg-indigo-200",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
  ],
};

export default tresoRouter;
