import Cookies from "js-cookie";

// token
const TokenKey = "fiifp-accessToken";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}

// refresh token
const TokenRefreshKey = "fiifp-refreshToken";

export function getTokenRefresh() {
  return Cookies.get(TokenRefreshKey);
}

export function setTokenRefresh(token) {
  return Cookies.set(TokenRefreshKey, token);
}
export function removeTokenRefresh() {
  return Cookies.remove(TokenRefreshKey);
}

// date expire token
const TokenDateExpireKey = "fiifp-expireToken";

export function getTokenExpire() {
  return Cookies.get(TokenDateExpireKey);
}

export function setTokenExpire(token) {
  return Cookies.set(TokenDateExpireKey, token);
}
export function removeTokenExpire() {
  return Cookies.remove(TokenDateExpireKey);
}

// account

const IsShow = "show-chiffre";

export function getShowAcc() {
  return Cookies.get(IsShow);
}

export function setShowAcc(value) {
  return Cookies.set(IsShow, value);
}
export function removeShowAcc() {
  return Cookies.remove(IsShow);
}
