import Layout from "@/layout";

const accountRouter = {
  path: "/comptes",
  component: Layout,
  name: "Compte",
  hidden: false,
  redirect: "/comptes/index",
  meta: {
    title: "Trésorie",
    icon: "Cash",
    bgcolor: "bg-blue-200 ",
    roles: ["USER-CREATE"], // you can set roles in root nav
  },
  children: [
    {
      path: "index",
      component: () => import("@/views/Account/index"),
      name: "compte-index",
    },
  ],
};

export default accountRouter;
