import Vue from "vue";
import VueRouter from "vue-router";
/** Layout */
import Layout from "@/layout";
import LayoutPos from "@/layout/index-post";
/** Router Modules */
import AccountRouter from "./modules/account";
import SalesRouter from "./modules/sales";
import ExpenseRouter from "./modules/expense";
import RepportintRouter from "./modules/rapporting";
import SettingRouter from "./modules/settings";
import TresorieRouter from "./modules/treso";
import PurchaseRouter from "./modules/purchase";
import StockRouter from "./modules/stock";
import ArticleRouter from "./modules/article"
import PosRouter from "./modules/pos"
Vue.use(VueRouter);

export const constantRoutes = [
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () => import("@/views/Dashboard/index.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "Home",
    hidden: true,
    redirect: "/dashboard",
    component: Layout,
    children: [
      {
        path: "dashboard",
        component: () => import("@/views/Dashboard/index.vue"),
        name: "dashboard",
      },
    ],
  },

  {
    path: "/signin",
    hidden: true,
    name: "signin",
    component: () => import("@/views/Signin/index.vue"),
  },


  {
    path: "/signup",
    hidden: true,
    name: "signup",
    component: () => import("@/views/Signup/index.vue"),
  },
  {
    path: "/organization",
    hidden: true,
    name: "Organization",
    component: () => import("@/views/Organization/index.vue"),
  },
];

export const asyncRoutes = [
  // ContactRouter,
  {
    path: "/pos",
    component: LayoutPos,
    hidden: true,
    redirect: "/pos/index",
    children: [
      {
        path: "index",
        hidden: true,
        name: "Caisse de vente",
        component: () => import("@/views/Pos/ticket.vue"),
      },
    ]
  },
  // clients

  {
    path: "/profile",
    component: Layout,
    hidden: true,
    redirect: "/profile/index",

    children: [
      {
        path: "index",
        component: () => import("@/views/Profile/index"),
        name: "profile",
        hidden: true,
        meta: {
          title: "Profile",
          // you can set roles in root nav
        },
      },
      {
        path: "password",
        component: () => import("@/views/Profile/password"),
        name: "password",
        hidden: true,
        meta: {
          title: "Modification mot de passe",
          // you can set roles in root nav
        },
      },

    ]
  },
  {
    path: "/customer",
    component: Layout,
    hidden: false,
    redirect: "/customer/index",
    children: [
      {
        path: "index",
        component: () => import("@/views/Contact/customer/index"),
        name: "customer-fiche",
        hidden: false,
        meta: {
          title: "Clients",
          icon: "bx bxs-group",
          activeMenu: "/customer/index",
          bgcolor: "bg-yellow-200",
          roles: ["USER-CREATE"], // you can set roles in root nav
        },
      },
      {
        path: "fiche/:id",
        component: () => import("@/views/Contact/customer/fiche"),
        name: "contact-index",
        hidden: true,
        meta: {
          title: "Clients",
          icon: "Contact",
          bgcolor: "bg-yellow-200",
          activeMenu: "/customer/index",
          roles: ["USER-CREATE"], // you can set roles in root nav
        },
      }
    ]
  },
  SalesRouter,

  PurchaseRouter,
  {
    path: "/supplier",
    component: Layout,
    hidden: false,
    redirect: "/supplier/index",
    children: [
      {
        path: "index",
        component: () => import("@/views/Contact/supplier/index"),
        name: "supplier-index",
        hidden: false,
        meta: {
          title: "Fournisseurs",
          icon: "bx bxs-user",
          bgcolor: "bg-yellow-200",
          activeMenu: "/supplier/index",
          roles: ["USER-CREATE"], // you can set roles in root nav
        },
      },
      {
        path: "fiche/:id",
        component: () => import("@/views/Contact/supplier/fiche"),
        name: "supplier-fiche",
        hidden: true,
        meta: {
          title: "Fournisseurs",
          icon: "Contact",
          activeMenu: "/supplier/index",
          bgcolor: "bg-yellow-200",
          roles: ["USER-CREATE"], // you can set roles in root nav
        },
      }
    ]
  },
  ArticleRouter,
  StockRouter,
  ExpenseRouter,
  AccountRouter,
  TresorieRouter,
  PosRouter,
  RepportintRouter,
  SettingRouter,
  //PosRouter,
];

const createRouter = () =>
  new VueRouter({
    //mode: "history",
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
