import Layout from "@/layout";

const PurchaseRouter = {
  path: "/purchases",
  component: Layout,
  name: "Purchase",
  hidden: false,

  meta: {
    title: "Achats",
    icon: "bx bxs-shopping-bag",
    bgcolor: "bg-red-200",
    roles: ["USER-CREATE"], // you can set roles in root nav
  },
  children: [
    {
      path: "index",
      component: () => import("@/views/Purchase/index"),
      name: "purchase",
      hidden: false,
      meta: {
        title: "Achats",
        icon: "bx bxs-shopping-bag",
        bgcolor: "bg-red-200",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    
    {
      path: "order",
      component: () => import("@/views/Purchase/order/index"),
      name: "purchase-order",
      hidden: true,
      meta: {
        activeMenu: "/purchases/index",
        title: "Ordres d'achat",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "order/create",
      component: () => import("@/views/Purchase/order/create"),
      name: "create-purchase-order",
      hidden: true,
      meta: {
        title: "Créer un ordre d'achat",
        activeMenu: "/purchases/index",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "order/update/:id",
      component: () => import("@/views/Purchase/order/update"),
      name: "update-purchase-order",
      hidden: true,
      meta: {
        title: "Modification un ordre d'achat",
        activeMenu: "/purchases/index",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "order/:id",
      component: () => import("@/views/Purchase/order/fiche"),
      name: "purchase-order-fiche",
      hidden: true,
      meta: {
        title: "Detail ordre d'achat",
        activeMenu: "/purchases/index",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },

    // facture fournisseur
    {
      path: "bills",
      component: () => import("@/views/Purchase/invoice"),
      name: "bills",
      hidden: true,
      meta: {
        title: "Factures achat",
        activeMenu: "/purchases/index",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "bills/create",
      component: () => import("@/views/Purchase/invoice/create"),
      name: "create-bill",
      hidden: true,
      meta: {
        activeMenu: "/purchases/index",
        title: "Créer une facture",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "bills/update/:id",
      component: () => import("@/views/Purchase/invoice/update"),
      name: "update-bill",
      hidden: true,
      meta: {
        activeMenu: "/purchases/index",
        title: "Modifier la facture",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "bills/:id",
      component: () => import("@/views/Purchase/invoice/fiche"),
      name: "bill-fiche",
      hidden: true,
      meta: {
        title: "Detail une facture",
        activeMenu: "/purchases/index",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "bills/sale/:id",
      component: () => import("@/views/Purchase/invoice/saleToPurchase.vue"),
      name: "sale-to-bill",
      hidden: true,
      meta: {
        activeMenu: "/purchases/index",
        title: "Transformer en facture d'achat",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "payment",
      component: () => import("@/views/Purchase/payment/newPayment"),
      name: "payment-bill",
      hidden: true,
      meta: {
        activeMenu: "/purchases/index",
        title: "Payer une facture",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
  ],
};

export default PurchaseRouter;
