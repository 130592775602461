import { login, logout, getInfo } from "@/api/user";
import {fetchPreferences} from "@/api/preference";
//import router from "@/router";

import {
  getToken,
  setToken,
  getTokenRefresh,
  setTokenRefresh,
  removeTokenRefresh,
  getTokenExpire,
  setTokenExpire,
  removeTokenExpire,
  removeToken,
} from "@/utils/cookies";
import { resetRouter } from "@/router";

const state = {
  token: getToken(),
  tokenRefresh: getTokenRefresh(),
  expireToken: getTokenExpire(),
  user: null,
  roles: [],
  preferences:[]
};

// mutation
const mutations = {
  SET_USER: (state, user) => {
    state.user = { ...user };
  },

  SET_REFRESH_TOKEN: (state, token) => {
    state.tokenRefresh = token;
  },

  SET_EXPIRE_TOKEN: (state, expireToken) => {
    state.expireToken = expireToken;
  },

  SET_TOKEN: (state, token) => {
    state.token = token;
  },

  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_PREFERENCE: (state, preferences) => {
    state.preferences = preferences;
  },
};

// action

const actions = {
  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      window.console.log(userInfo);
      const { email, password, deviceInfo } = userInfo;

      login({
        email: email.trim(),
        password: password.trim(),
        device_info: deviceInfo,
      })
        .then((data) => {
          commit("SET_TOKEN", data.accessToken);
          commit("SET_REFRESH_TOKEN", data.refreshToken);
          commit("SET_EXPIRE_TOKEN", data.expiryDuration);

          setToken(data.accessToken);
          setTokenRefresh(data.refreshToken);
          let NOW_DATE = parseInt(new Date().getTime() / 1000);
          setTokenExpire(data.expiryDuration + NOW_DATE);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // information

  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then((response) => {
          const { data } = response;
          window.console.log(data)
          if (!data) {
            reject("Verification failed, please Login again.");
          }

          // roles must be a non-empty array
          /*  if (!roles || roles.length <= 0) {
            reject("getInfo: roles must be a non-null array!");
          } */
          let user = {
            name: data.full_name,
            email: data.email,
            phone: data.phone,
            status: data.status,
            store: data.store,
            store_id: data.store_id,
            currency_code : data.currency_code,
            currency_name : data.currency_name,
            country : data.country
          };
          commit("SET_USER", user);
          let roles = data.authorities;
          commit("SET_ROLES", roles);

          resolve({ user, roles });
        })
        .catch((error) => {
          reject(error);
        });

        fetchPreferences().then(res => commit("SET_PREFERENCE", res.data[0])).catch(error => reject(error))
    });
  },
  // logout

  logout({ dispatch }) {
    return new Promise((resolve, reject) => {
      const data = {
        token: getToken(),
        device_info: {
          device_id: 453454555,
          device_type: "Mac book pros",
        },
      };

      logout(data)
        .then(() => {
          dispatch("resetToken");
          resetRouter();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_USER", {});
      commit("SET_ROLES", []);
      commit("SET_TOKEN", "");
      commit("SET_REFRESH_TOKEN", "");
      commit("SET_EXPIRE_TOKEN", "");

      removeToken();
      removeTokenExpire();
      removeTokenRefresh();
      resolve();
    });
  },

  // charge les roles

  /* changeRoles({ commit, dispatch }, role) {
    return new Promise((resolve) => {
      const token = role + "-token";

      commit("SET_TOKEN", token);
      setToken(token);

      const { roles } = dispatch("getInfo");

      // generate accessible routes map based on roles
      const accessRoutes = dispatch("permission/generateRoutes", roles, {
        root: true,
      });
      console.log(roles);
      // dynamically add accessible routes
      router.addRoutes(accessRoutes);

      // reset visited views and cached views
      dispatch("tagsView/delAllViews", null, { root: true });

      resolve();
    });
  },*/
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
