<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import "animate.css";
export default {
  name: "App",
};
</script>

<style>
/* Google Fonts Import Link */

/*import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap"); */

@import url("https://fonts.cdnfonts.com/css/dm-sans");

* {
  /* margin: 0;
  padding: 0;
  box-sizing: border-box;
  /*font-family: "Poppins", sans-serif;*/
  /*font-family: "Inter", sans-serif;*/
  /* Body 
  font-style: normal;
   font-weight: 400;
   font-size: 14px;
  line-height: 21px; /*
  /* identical to box height */
  font-size: 15px;
  font-weight: Normal;
  letter-spacing: 0.5px;

  font-family: "DM Sans", sans-serif;
}
</style>
