import Cookies from "js-cookie";

// state
const state = {
  sidebar: {
    opened: Cookies.get("sidebarStatus")
      ? !!+Cookies.get("sidebarStatus")
      : false,
    withoutAnimation: false,
  },
  device: "desktop",
  opendMenu : false
};

// mutations

const mutations = {
  TOGGLE_SIDEBAR: (state) => {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      Cookies.set("sidebarStatus", 1);
    } else {
      Cookies.set("sidebarStatus", 0);
    }
  },
  TOGGLE_OPEND_MENU_SHORTCUTE: (state) =>{
    state.opendMenu = true;
  },
  TOGGLE_CLOSE_MENU_SHORTCUTE: (state) =>{
    state.opendMenu = false;
  },
  TOGGLE_OPEN_SUBMENU: (state) => {
    state.sidebar.opened = true;
    Cookies.set("sidebarStatus", 1);
  },
  TOGGLE_CLOSE_SUBMENU: (state) => {
    state.sidebar.opened = false;
    Cookies.set("sidebarStatus", 0);
  },

  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set("sidebarStatus", 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device;
  },
};

// actions
const actions = {
  toggleSideBar({ commit }) {
    commit("TOGGLE_SIDEBAR");
  },
  toggleOpenSubMenu({ commit }) {
    commit("TOGGLE_OPEN_SUBMENU");
  },
  toggleCloseSubMenu({ commit }) {
    commit("TOGGLE_CLOSE_SUBMENU");
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit("CLOSE_SIDEBAR", withoutAnimation);
  },
  toggleDevice({ commit }, device) {
    commit("TOGGLE_DEVICE", device);
  },

  toggleOpenMenuShortCute({ commit }) {
    commit("TOGGLE_OPEND_MENU_SHORTCUTE");
  },

  toggleCloseMenuShortCute({ commit }) {
    console.log('fermer le modal')
    commit("TOGGLE_CLOSE_MENU_SHORTCUTE");
  },
};

// getter

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
