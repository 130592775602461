<template>
  <div class="flex flex-col">
    <div
      class="lg:flex bg-yellow border-t lg:items-center lg:justify-between py-4 px-10"
    >
      <!--  <div class="flex-1 min-w-0 flex justify-between py-4"> -->
      <h2
        class="text-2xl font-semibold leading-7 text-gray-900 sm:text-2xl sm:truncate"
      >
        <slot name="title"></slot>
      </h2>

      <div class="flex justify-end">
        <slot name="menu"></slot>
      </div>
      <!-- </div> -->
    </div>
    <div class="flex px-10 mt-4">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main",
};
</script>

<style lang="scss" scoped></style>
