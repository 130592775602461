import Layout from "@/layout";

const SalesRouter = {
  path: "/sales",
  component: Layout,
  name: "Sales",
  hidden: false,
  meta: {
    title: "Ventes",
    icon: "bx bxs-cart-alt",
    bgcolor: "bg-green-200",
    roles: ["USER-CREATE"], // you can set roles in root nav
  },
  children: [
    {
      path: "index",
      component: () => import("@/views/Sales/index"),
      name: "Vente",
      hidden: false,
      meta: {
        title: "Ventes",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },

    {
      path: "invoice",
      component: () => import("@/views/Sales/invoice"),
      name: "Invoice",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Factures",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "invoice/create",
      component: () => import("@/views/Sales/invoice/Create"),
      name: "create-invoice",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Créer une commande",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "invoice/transformer/:id",
      component: () => import("@/views/Sales/invoice/purchaseToInvoice.vue"),
      name: "create-invoice",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Transformer ",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },

    // devis
    {
      path: "quote",
      component: () => import("@/views/Sales/quote/index"),
      name: "index-quote",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Devis",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
      children: [],
    },
    {
      path: "quote/create",
      component: () => import("@/views/Sales/quote/create"),
      name: "create-quote",

      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Créer un devis",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "quote/:id",
      component: () => import("@/views/Sales/quote/fiche"),
      name: "fiche-quote",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Detail Devis",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "quote/update/:id",
      component: () => import("@/views/Sales/quote/update"),
      name: "update-quote",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Modification Devis",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },

    // commande
    {
      path: "orders",
      component: () => import("@/views/Sales/order/index"),
      name: "index-order",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Bon de commandes",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "orders/create",
      component: () => import("@/views/Sales/order/create"),
      name: "create-order",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Créer une commande",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "orders/:id",
      component: () => import("@/views/Sales/order/fiche"),
      name: "fiche-order",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Detail commande",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "orders/update/:id",
      component: () => import("@/views/Sales/order/update"),
      name: "update-order",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Modification commande",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
     // note de crédit
     {
      path: "credit-note",
      component: () => import("@/views/Sales/creditnote/index"),
      name: "index-creditnote",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Note de crédit",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "credit-note/create",
      component: () => import("@/views/Sales/creditnote/create"),
      name: "create-credit-note",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Créer une note crédit",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "credit-note/:id",
      component: () => import("@/views/Sales/creditnote/fiche"),
      name: "fiche-credit-note",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Detail note crédit",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "credit-note/update/:id",
      component: () => import("@/views/Sales/creditnote/update"),
      name: "update-credit-note",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Modification note crédit",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    // bon de livraison
    /* {
      path: "delivery-note",
      component: () => import("@/views/Sales/bonlivraison/index"),
      name: "Delivery-note",
      hidden: false,
      meta: {
        title: "Bon de livraison",

        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "delivery-note/create",
      component: () => import("@/views/Sales/bonlivraison/create"),
      name: "create-delivery-note",
      hidden: true,
      meta: {
        title: "Créer un bon de livraison",
        activeMenu: "/sales/index",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "delivery-note/:id",
      component: () => import("@/views/Sales/bonlivraison/fiche"),
      name: "fiche-delivery-note",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Details bon de livraison",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },*/

    {
      path: "invoice/update/:id",
      component: () => import("@/views/Sales/invoice/Update"),
      name: "invoice-update",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Modifier une facture",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "invoice/:id",
      component: () => import("@/views/Sales/invoice/Fiche"),
      name: "invoice-fiche",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Detail facture",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "/invoice/order-to-invoice/:id",
      component: () => import("@/views/Sales/invoice/OrderToInvoice"),
      name: "conv-order-to-invoice",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Conversion commande en facture",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    // paiement
    {
      path: "payments",
      component: () => import("@/views/Sales/payment/index"),
      name: "Payment-invoice",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Paiements",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "payments/create",
      component: () => import("@/views/Sales/payment/create"),
      name: "create-payment-invoice",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Créer un reglèment",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "payments/:id",
      component: () => import("@/views/Sales/payment/fiche"),
      name: "Payment-invoice-fiche",
      hidden: true,
      meta: {
        activeMenu: "/sales/index",
        title: "Detail reglèment",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
  ],
};

export default SalesRouter;
