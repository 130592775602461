import Layout from "@/layout";

const ExpensesRouter = {
  path: "/expense",
  component: Layout,
  name: "expense",
  hidden: false,

  children: [
    {
      path: "expense",
      component: () => import("@/views/Purchase/expense/index"),
      name: "expense-index",
      meta: {
        title: "Depenses",
        icon: "bx bx-folder-open",
        bgcolor: "bg-yellow-200",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
  ],
};

export default ExpensesRouter;
