import Vue from "vue";
import router from "./router";
import store from "./store";
import App from "./App.vue";

import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/fr";
import "./permission";

import "element-ui/lib/theme-chalk/index.css";
import "boxicons/css/boxicons.min.css";
import "@/styles/index.scss"; // global css
import "./index.css";
import Main from "@/components/Main";
import VueApexCharts from "vue-apexcharts";

Vue.use(ElementUI, { locale });

Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
Vue.component("main-component", Main);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
