import request from "@/utils/request";

export function fetchPreferences() {
  return request({
    url: "/preferences",
    method: "get"
  });
}



export function updateUser(id,data) {
  return request({
    url: `/preferences/${id}`,
    method: "put",
    data,
  });
}
