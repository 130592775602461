import Layout from "@/layout";

const stockRouter = {
  path: "/stock",
  component: Layout,
  name: "stock",
  hidden: false,
  redirect: "/stock/index",
  children: [
    {
      path: "index",
      component: () => import("@/views/Stock/index"),
      name: "stock-index",
      hidden: false,
      meta: {
        title: "Gestion stocks",
        //icon: "bx bxs-package",
        icon: "bx bxs-package",
        bgcolor: "bg-purple-200",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },

    }

    ,
    {
      path: "/stock/entree-stock",
      component: () => import("@/views/Stock/EntryVoucher"),
      name: "entree-stock",
      hidden: true,
      meta: {
        activeMenu :"/stock/index",
        title: "Entrées de stock",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "/stock/entree-stock/new",
      component: () => import("@/views/Stock/EntryVoucher/create"),
      name: "entree-stock-new",
      hidden: true,
      meta: {
        activeMenu :"/stock/index",
        title: "Créer entrée de stock",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "/stock/entree-stock/:id",
      component: () => import("@/views/Stock/EntryVoucher/fiche"),
      name: "entree-stock-fiche",
      hidden: true,
      meta: {
        activeMenu :"/stock/index",
        title: "Fiche bon d'entrée ",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    // bon de sortie
    {
      path: "/stock/sortie-stock",
      component: () => import("@/views/Stock/OutingVoucher"),
      name: "sortie-stock",
      hidden: true,
      meta: {
        activeMenu :"/stock/index",
        title: "Bon d'entrée",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "/stock/sortie-stock/new",
      component: () => import("@/views/Stock/OutingVoucher/create"),
      name: "sortie-stock-new",
      hidden: true,
      meta: {
        activeMenu :"/stock/index",
        title: "Bons d'entrée",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "/stock/sortie-stock/:id",
      component: () => import("@/views/Stock/OutingVoucher/fiche"),
      name: "sortie-stock-fiche",
      hidden: true,
      meta: {
        activeMenu :"/stock/index",
        title: "Fiche bon de sortie ",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },

    // mouvement de stock
    {
      path: "/stock/movements-stock",
      component: () => import("@/views/Stock/Movement"),
      name: "movement-stock",
      hidden: true,
      meta: {
        activeMenu :"/stock/index",
        title: "Mouvements",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },

    // inventaire stock
    {
      path: "/stock/inventory",
      component: () => import("@/views/Stock/Inventory"),
      name: "inventory",
      hidden: true,
      meta: {
        activeMenu :"/stock/index",
        title: "Inventaires",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "/stock/inventory/:id",
      component: () => import("@/views/Stock/Inventory/Fiche.vue"),
      name: "inventory-fiche",
      hidden: true,
      meta: {
        activeMenu :"/stock/index",
        title: "Fiche inventaire",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },

    // transfert de stock

    {
      path: "transfer/fiche/:id",
      component: () => import("@/views/Stock/Transfer/fiche"),
      name: "transfer-stock-fiche",
      hidden: true,
      meta: {
        activeMenu :"/stock/index",
        title: "Fiche de transfert de stock",
        bgcolor: "bg-yellow-200",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "transfer/create",
      component: () => import("@/views/Stock/Transfer/create"),
      name: "transfer-create",
      hidden: true,
      meta: {
        activeMenu :"/stock/index",
        title: "Créer un transfer de stock",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },

     // ajustement de stock

     {
      path: "adjustment/fiche/:id",
      component: () => import("@/views/Stock/Adjutment/fiche"),
      name: "adjutment-stock-fiche",
      hidden: true,
      meta: {
        activeMenu :"/stock/index",
        title: "Fiche ajustement de stock",
        bgcolor: "bg-yellow-200",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "adjustment/create",
      component: () => import("@/views/Stock/Adjutment/create"),
      name: "adjutment-create",
      hidden: true,
      meta: {
        activeMenu :"/stock/index",
        title: "Créer un ajustement",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
  ],
};

export default stockRouter;
