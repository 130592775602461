<template>
  <div>
    <el-dialog
      title="MENU AJOUT RAPIDE"
      :visible="open_menu"
      width="40%"
      top="3vh"
      @close="toggleCloseMenuShortCute"
    >
      <div class="menu_rapid">
        <div class="menu_sale">
          <div class="menu_item" @click="handleSelected('INVOICE')">
            <i class="icon bx bxs-file-doc"></i>
            <div class="title">facture de vente</div>
          </div>
          <div class="menu_item" @click="handleSelected('CUSTOMER')">
            <i class="icon bx bxs-user-plus"></i>
            <div class="title">créer un client</div>
          </div>
          <div class="menu_item" @click="handleSelected('POS')">
            <i class="icon bx bxs-calculator"></i>
            <div class="title">caisse de vente</div>
          </div>
        </div>
        <div class="menu_sale">
          <div class="menu_item" @click="handleSelected('PURCHASE')">
            <i class="icon bx bxs-basket"></i>
            <div class="title">facture d'achat</div>
          </div>
          <div class="menu_item" @click="handleSelected('SUPPLIER')">
            <i class="icon bx bxs-user"></i>
            <div class="title">fournisseur</div>
          </div>
          <div class="menu_item" @click="handleSelected('EXPENSE')">
            <i class="icon bx bx-money-withdraw"></i>
            <div class="title">dépense</div>
          </div>
        </div>
        <div class="menu_sale">
          <div class="menu_item" @click="handleSelected('ARTICLE')">
            <i class="icon bx bxs-purchase-tag"></i>
            <div class="title">article</div>
          </div>
          <div class="menu_item" @click="handleSelected('TRANSFER')">
            <i class="icon bx bx-transfer"></i>
            <div class="title">transfert de stock</div>
          </div>
          <div class="menu_item" @click="handleSelected('INVENTORY')">
            <i class="icon bx bxs-building-house"></i>
            <div class="title">inventaire</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "MenuShortCute",
  computed: {
    ...mapGetters(["open_menu"]),
  },

  methods: {
    toggleCloseMenuShortCute() {
      this.$store.dispatch("app/toggleCloseMenuShortCute");
    },

    handleSelected(value) {
      switch (value) {
        case "POS": {
          const routeData = this.$router.resolve({ path: "/pos" });
          window.open(routeData.href, "_blank");
          break;
        }

        case "INVOICE":
          this.$router.push({ path: "/sales/invoice/create" });
          break;

        case "CUSTOMER":
          this.$router.push({
            name: "customer-fiche",
            params: { newcustomer: true },
          });
          break;

        case "PURCHASE":
          this.$router.push({ path: "/purchases/bills/create" });
          break;
        case "EXPENSE":
          this.$router.push({
            name: "expense-index",
            params: { newexpense: true },
          });
          break;
        case "SUPPLIER":
          this.$router.push({
            name: "supplier-index",
            params: { newsupplier: true },
          });
          break;
        case "ARTICLE":
          this.$router.push({ path: "/article/create" });
          break;
        case "TRANSFER":
          this.$router.push({ path: "/stock/transfer/create" });
          break;
        case "INVENTORY":
          this.$router.push({
            name: "stock-index",
            params: { newInventory: true },
            query: { tab: "INVENTORY" },
          });
          break;
      }
      this.toggleCloseMenuShortCute();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.menu_rapid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .menu_sale {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
  }

  .menu_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    flex: 1;
    height: 100px;
    margin-left: 10px;
    border: 1px solid $primary;
    border-radius: 6px;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
    cursor: pointer;
    .title,
    .icon {
      color: $primary;
    }
    .icon {
      font-size: 30px;
    }
    .title {
      margin-top: 5px;
      font-size: 16px;
    }
  }
  .menu_item:hover {
    border: 1px solid $primary;
    background: $primary;
  }
  .menu_item:hover * {
    color: $white;
  }
}
</style>
