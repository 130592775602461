import Layout from "@/layout";
const rapportRouter = {
  path: "/settings",
  component: Layout,
  children: [
    {
      path: "index",
      component: () => import("@/views/Settings/index"),
      name: "setting-index",
      hidden: true,
      meta: {
        title: "Mon entreprise",
        icon: "bx bxs-cog",

        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
  ],
};

export default rapportRouter;
