import Layout from "@/layout";

const PosRouter = {
  path: "/point-of-sales",
  component: Layout,
  name: "POS",
  hidden: false,
  redirect: "/point-of-sale/index",
  meta: {
    title: "Caisse",
    icon: "Calcul",
    bgcolor: "bg-green-200",
    roles: ["USER-CREATE"], // you can set roles in root nav
  },
  children: [
    {
      path: "index",
      component: () => import("@/views/Pos/index"),
      name: "pos-index",
      meta: {
        title: "Caisses",
        icon: "bx bx-calculator",
        bgcolor: "bg-yellow-200",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
    {
      path: "fiche/:id",
      component: () => import("@/views/Pos/fiche"),
      name: "pos-fiche",
      hidden: true,
      meta: {
        activeMenu: "/point-of-sales/index",
        title: "Fiche caisse",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },
    },
  ],
};
export default PosRouter;
