<template>
  <div class="navbar">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <div class="right-menu">
      <div class="right-menu-item store hover-effect">
        <router-link to="/settings/index">
          <div>
            <span class="bx bxs-store"></span> {{ user.store }}
            <span class="text-gray-500 text-sm">({{ user.store_id }})</span>
          </div>
        </router-link>
      </div>

      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        trigger="click"
      >
        <div class="avatar-wrapper">
          <i class="el-icon-user mr-2"></i>
          <span> {{ user.email }}</span>
          <i class="el-icon-arrow-down ml-2" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/profile/index">
            <el-dropdown-item>
              <i class="el-icon-user ml-2" /> Mon profile</el-dropdown-item
            >
          </router-link>
          <router-link to="/profile/password">
            <el-dropdown-item>
              <i
                @click="handleUpdatePassword"
                class="el-icon-office-building ml-2"
              />
              Mot de passe</el-dropdown-item
            >

            <el-dropdown-item divided @click.native="logout">
              <span style="display: block">
                <i class="el-icon-unlock ml-2" /> Se deconnecter</span
              >
            </el-dropdown-item>
          </router-link>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";

export default {
  components: {
    // Breadcrumb,
    Hamburger,
  },
  computed: {
    ...mapGetters(["sidebar", "device", "user"]),
  },

  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
    handleUpdatePassword() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    .logout {
      cursor: pointer;
    }
    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 16px;
      color: $text-color;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(70, 94, 253, 0.055);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 0px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          font-weight: 500;
          border-radius: 10px;
        }

        .el-icon-arrow-down {
          cursor: pointer;
          position: absolute;
          right: -30px;
          top: 15px;
          padding-right: 2px;
          font-weight: 500;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
