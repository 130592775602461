import Layout from "@/layout";

const articleRouter = {
  path: "/article",
  component: Layout,
  name: "Article",
  redirect: '/article/index',
  hidden: false,
  children: [
    {
      path: "index",
      component: () => import("@/views/Stock/Article/index"),
      name: "list-article",
      hidden: false,
      meta: {
        title: "Articles",
        icon: "bx bxs-purchase-tag",
        bgcolor: "bg-purple-200",
        roles: ["USER-CREATE"], // you can set roles in root nav
      },

    },
        // articles

        {
          path: "create",
          component: () => import("@/views/Stock/Article/create"),
          name: "create-article",
          hidden: true,
          meta: {
            activeMenu: "/article/index",
            title: "Nouveau produit",
            icon: "Contact",
            bgcolor: "bg-yellow-200",
            roles: ["USER-CREATE"], // you can set roles in root nav
          },
        },
        {
          path: "update/:id",
          component: () => import("@/views/Stock/Article/update"),
          name: "update-article",
          hidden: true,
          meta: {
            activeMenu: "/article/index",
            title: "Modifier produit",
            icon: "Contact",
            bgcolor: "bg-yellow-200",
            roles: ["USER-CREATE"], // you can set roles in root nav
          },
        },
        {
          path: "fiche/:id",
          component: () => import("@/views/Stock/Article/fiche"),
          name: "article-fiche",
          hidden: true,
          meta: {
            activeMenu: "/article/index",
            title: "Fiche produit",
            icon: "Contact",
            bgcolor: "bg-yellow-200",
            roles: ["USER-CREATE"], // you can set roles in root nav
          },
        },

  ]

};

export default articleRouter;
