import request from "@/utils/request";

export function login(data) {
  return request({
    url: "/auth/signin",
    method: "post",
    data,
  });
}

export function registerUser(data) {
  return request({
    url: "/auth/signup",
    method: "post",
    data,
  });
}

export function updateUser(data, username) {
  return request({
    url: `/users/update/${username}`,
    method: "put",
    data,
  });
}

export function getInfo() {
  return request({
    url: "/users/me",
    method: "get",
  });
}

export function logout(data) {
  return request({
    url: "/users/logout",
    method: "put",
    data,
  });
}

export function updatePassword(data) {
  return request({
    url: "/users/password",
    method: "put",
    data,
  });
}
